import React from "react"
//COMPONENTS
import AboutImage from "./assets/Ivone-Foto2.jpg"
import About from "../components/about"
import Card from "../components/card"
import Navigation from "../components/navigation"
import { Grid } from "@material-ui/core"
import BulletItem from "../components/bullet-item"
import Article from "../components/article"
import Newsletter from "../components/newsletter"
import Section from "../components/section"
import HeroSection from "../components/hero-section"
import Carousel from "../components/carousel"
import Testimonial from "../components/testimonial"

//IMAGES
import HeroImage from "./assets/Ivone-Foto5.jpg"
import ImageTurkisSea from "./assets/turkis-sea.jpg"
import ImageTurkisWay from "./assets/jailam-rashad-hSFgNXOztLU-unsplash.jpg"
import ImageSesiones from "./assets/Sesiones-pexels-mentatdgt-1311518.jpg"
import ImageConfianza from "./assets/CONFIDENCE-pexels-moose-photos-1036622.jpg"
import ImageConfidenceRev from "./assets/ConfidenceRevolution.jpg"

//ICONS
import { IoIosArrowForward } from "react-icons/io"

import { IoMdCheckmarkCircle } from "react-icons/io"
import { BsCheckCircle } from "react-icons/Bs"
import Footer from "../components/footer"

export default function Home() {
  const bulletIcon1 = <IoIosArrowForward color="#269fb2" size="36px" />
  const bulletIcon2 = <BsCheckCircle color="#21447d" size="26px" />

  const Testimonio_8 = `Al grano: te recomiendo a Ivone totalmente. 
  Si hubiera encontrado este programa antes me hubiera ahorrado mucha frustración 
  y mucho sufrimiento y hubiera logrado, todo lo que he logrado desde que empece este programa, 
  hace mucho tiempo. Lo que más me gusto es el enfoque de estrategia y acción, porque pude ver la diferencia en mi vida 
  inmediatamente. No lo pienses más, ¡Apúntate! `
  const TestName_8 = "Laura Adán"

  const Testimonio_9 = `¡Transformación total! El coaching con Ivone marcó un antes y un después en mi vida!. 
    Hoy me siento confiada de mi misma como nunca antes lo había sentido, y sé que puedo lograr todo lo que me propongo.`
  const TestName_9 = "Eva López"

  const Testimonio_10 = `Gracias al coaching con Ivone, logré renunciar al trabajo con el que ya tenía años insatisfecha. 
    En mi nuevo trabajo de verdad siento que estoy aprovechando toda mi experiencia y me siento mucho más felíz. 
    Además todas mis amigas notaron el cambio que dí!.  Ahora me siento capaz de lograr muchas cosas que 
    antes veía imposibles. ¡Gracias Ivone!`
  const TestName_10 = "Jazmín."

  return (
    <>
      <Navigation active={0} />
      <HeroSection
        bgImage={HeroImage}
        id="Home"
        //subtitle="Amplifica tu confianza para conseguir lo que quieres."
        h1="Conecta con el poder que llevas dentro y 
        úsalo para materializar tus sueños e incrementar tus ingresos
        dónde quiera que te encuentres."
        overlay="part"
        overlayClassName="white flip-h"
        titleClassName="left"
        color="turkis-blue1"
        forceAspectRatioOnMobile
        /* h1="Uso el poder de las emociones para 
        amplificar la confianza en ti misma y 
        te ayudo a construir la estrategia que necesitas 
        para generar los ingresos que mereces." */
      ></HeroSection>

      <Section color="turkis-blue1" id="Center-text" small>
        <Article className="white center playfair lineheight-large">
          <span className="font-size-medium">
            Descarga mi Guía{" "}
            <span className="bold">
              "Cómo descubrir tu propósito y conectar con tu confianza"
            </span>
          </span>
          <Newsletter tag="GIVEAWAY" value="RECONECTA" label="Quiero la guía" />
        </Article>
      </Section>

      <Section color="white">
        <Article
          layout="wide"
          titleClassName="tenor bold blue1 font-size-verylarge lineheight-large"
          //title="Conozco muy bien lo qué sientes porque yo también he estado ahí"
          title="¿Te identificas con algunas de estas ideas?"
        >
          <Grid container spacing={1}>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
                Te sientes insatisfecha con tu vida por que crees que estas
                desperdiciando tus capacidades y las habilidades que has
                desarrollado en tantos años de experiencia acumulada.
              </BulletItem>

              <BulletItem icon={bulletIcon1}>
                Por tu cabeza pasan ideas como: "¿Seré lo suficientemente
                buena?", "¿Sabré lo suficiente?"". "¿Y si no estoy lista
                todavía?".
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Estas paralizada por las dudas de no saber qué hacer o de no
                saber por dónde empezar. Todas estas dudas te causan ansiedad
                que va en aumento.
              </BulletItem>
            </Grid>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
                Sientes la intensa necesidad de poder vencer ese miedo y esas
                dudas para poder contribuir al mundo con tu conocimiento,
                creatividad, inteligencia y experiencia.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Sientes que tienes mucho que ofrecer a los demás, pero no sabes
                por dónde empezar. ¡Te sientes super atorada!
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Por momentos tomas impulso y comienzas a trabajar en una idea,
                pero después de unos días pierdes la motivación y no sabes por
                dónde se fue.
              </BulletItem>
            </Grid>
          </Grid>
        </Article>
      </Section>

      <Section color="white">
        <Article className="narrow playfair bold center font-size-verylarge turkis-blue1 lineheight-verylarge">
          Te entiendo bien.
          <br />
          Sé por lo que estas pasando y también sé lo que te esta deteniendo.
          <br />
          Porque yo también me sentía así.
          <br />
          <br />
        </Article>
        <Article className="center font-size-medium lineheight-verylarge">
          Todo eso que sientes es un llamado a un propósito mayor, es tu voz
          interior pidiéndote que
          <br />
          <strong>
            {" "}
            evoluciones tu creencias, que confíes y empieces a actuar{" "}
          </strong>
          <br /> para poder manifestar todo eso que sueñas.
          <br />
          <br />
        </Article>
        <Article className="narrow playfair bold center font-size-verylarge blue1 lineheight-verylarge">
          ¡Estas muy cerca de lograrlo, por eso estas aquí!
        </Article>
      </Section>

      <Section
        className="bold  font-size-verylarge"
        color="turkis-blue1"
        bgImage={ImageTurkisSea}
        overlay="full"
        overlayColor="rgba(250, 246, 246, .3)"
      >
        <Article className="center  blue1 font-size-medium lineheight-large">
          {/* <Card className="blue"> */}
          Mujer,
          <br />
          eres{" "}
          <span className="bolder">
            sensible, talentosa, inteligente, apasionada, creativa, intuitiva….
          </span>
          <br />
          Y por todo eso
          <br />
          <br />{" "}
          <span className="bolder">
            ¡El valor que puedes aportar a la sociedad, es incalculable!{" "}
          </span>
          <br />
          ¡Tienes una misión!
          <br />
          <br />
          Tal vez ya lo sabes, pero
          <br />
          algo te esta impidiendo materializarla y no sabes qué es.
          {/* </Card> */}
        </Article>
      </Section>

      <Section color="white">
        <Article>
          <div className="narrow  playfair bold center font-size-large turkis-blue1 lineheight-verylarge">
            ¿Te gustaría estar más segura de tus decisiones y de tus acciones y
            sentirte confiada de que puedes materializar esa idea genial a la
            que le has estado dando vueltas?
          </div>

          <br />
          <br />
          <br />
          <div className="narrow  raleway center font-size-medium blue1 lineheight-verylarge">
            Permíteme ayudarte a que apartes las dudas y los obstáculos que te
            impiden mostrarle al mundo todo lo que tienes para ofrecerle, para
            que puedas generar los ingresos que te mereces.
          </div>
        </Article>
      </Section>

      <Section color="white" id="sobre mi">
        <Article layout="wide">
          <About
            titleClassName="tenor bold blue1 font-size-verylarge  left lineheight-large"
            title="Soy Ivone Herrera"
            image={AboutImage}
          >
            <span className="turkis-blue1 font-size-medium lineheight-large">
              Mi método combina
            </span>
            <br />
            <span className="bold turkis-blue1 font-size-large lineheight-verylarge">
              Sensibilidad, Estrategia y Acción
            </span>
            <br />
            <br />
            <span className=" blue1  font-size-medium ">
              Soy Mentora, Coach de Confianza, Coach de Emociones y de
              Renovación de Creencias.
              <br />
              <br />
              Entrenadora en Psicología Positiva y Programación Neurolingüística
              y también Ingeniera Física, con Maestría en Simulaciones
              Computacionales.
            </span>
            <br />
            <br />
            <br />
            <span className="bold turkis-blue1 font-size-large lineheight-large">
              Soy una creyente absoluta en el talento y la capacidad femeninas.
            </span>
          </About>
        </Article>
      </Section>

      <Section color="white" id="Center-text">
        <Article className="center blue1  playfair bold font-size-extralarge lineheight-large">
          El mundo necesita urgentemente de tu talento, tu capacidad y tu
          experiencia.
          <br /> <br />
          <div className="raleway center turkis-blue1 bold font-size-large lineheight-large ">
            ¡Deja que la mujer segura y confiada que vive dentro de ti
            <br /> se muestre en todo su esplendor y consiga lo que quiere!
          </div>
        </Article>
      </Section>

      <Section layout="narrow" color="blue1" bgImage={ImageTurkisSea}>
        <Article className="blue1">
          <Card
            title="En mis programas y sesiones aprenderás herramientas escenciales que te serán útiles de por vida 
            con las que:"
            titleClassName="playfair blue1 bold center font-size-verylarge lineheight-large"
            className="lineheight-normal turkis-blue1"
          >
            <br />
            <br />

            <Grid container spacing={1}>
              <Grid item md={6} sm={12}>
                <BulletItem
                  icon={<IoMdCheckmarkCircle color="#21447d" size="26px" />}
                >
                  Reconocerás tu valor y tus talentos
                </BulletItem>

                <BulletItem
                  icon={<IoMdCheckmarkCircle color="#21447d" size="26px" />}
                >
                  Amplificarás tu confianza
                </BulletItem>

                <BulletItem
                  icon={<IoMdCheckmarkCircle color="#21447d" size="26px" />}
                >
                  Desarrollarás una nueva y poderosa mentalidad
                </BulletItem>

                <BulletItem
                  icon={<IoMdCheckmarkCircle color="#21447d" size="26px" />}
                >
                  Desbloquearás y activarás tu potencial
                </BulletItem>
              </Grid>
              <Grid item md={6} sm={12}>
                <BulletItem
                  icon={<IoMdCheckmarkCircle color="#21447d" size="26px" />}
                >
                  Vencerás tus dudas
                </BulletItem>

                <BulletItem
                  icon={<IoMdCheckmarkCircle color="#21447d" size="26px" />}
                >
                  Aprenderás a mantenerte motivada
                </BulletItem>

                <BulletItem
                  icon={<IoMdCheckmarkCircle color="#21447d" size="26px" />}
                >
                  Te fijarás metas realistas y lograrás cumplirlas
                </BulletItem>

                <BulletItem
                  icon={<IoMdCheckmarkCircle color="#21447d" size="26px" />}
                >
                  Podrás obtener las ganancias que quieres
                </BulletItem>
              </Grid>
            </Grid>
          </Card>
        </Article>
      </Section>

      <Section color="beige1">
        <Article
          layout="wide"
          titleClassName="blue1 tenor font-size-verylarge lineheight-large"
          title="DESCUBRE CÓMO PODEMOS TRABAJAR JUNTAS"
        >
          <Grid container spacing={3} justify="center">
            <Grid item md={4} xs={10}>
              <Card
                link="/proximamente"
                avatar={ImageSesiones}
                titleClassName="tenor font-size-medium"
                className="center"
                title={
                  <span>
                    Power Coaching
                    <br />
                    <strong>Sesiones 1 to 1</strong>
                  </span>
                }
              >
                Nos enfocamos en tus desafíos personales y en como superarlos.
              </Card>
            </Grid>
            <Grid item md={4} xs={10}>
              <Card
                link="/curso-amplifica-tu-confianza"
                avatar={ImageConfianza}
                titleClassName="tenor font-size-medium"
                className="center"
                title={
                  <span>
                    Curso
                    <br />
                    <strong>Amplifica tu Confianza</strong>
                  </span>
                }
              >
                Descubre como, tener confianza en ti misma, puede transformar
                radicalmente tu vida.
              </Card>
            </Grid>
            <Grid item md={4} xs={10}>
              <Card
                link="/proximamente"
                avatar={ImageConfidenceRev}
                titleClassName="tenor font-size-medium"
                title={
                  <span>
                    Programa Premium
                    <br />
                    <strong>The Confidence Revolution</strong>
                  </span>
                } // "Programa Premium Personalizado 'The Confidence Revolution' "
                className="center"
              >
                Experimenta el poder que llevas dentro y úsalo para conquistar
                tus sueños.
              </Card>
            </Grid>
          </Grid>
        </Article>
      </Section>

      <Section
        className="playfair font-size-verylarge"
        color="turkis-blue1"
        bgImage={ImageTurkisWay}
        overlay="full"
        overlayColor="rgba(250, 246, 246,.4)"
      >
        <Article layout="wide">
          <Card
            title="Otros aspectos en los que también te puedo ayudar"
            titleClassName="playfair blue1 bold center font-size-verylarge lineheight-large"
            className="lineheight-normal turkis-blue1 lineheight-large"
          >
            <br />
            <Grid container spacing={1}>
              <Grid item md={6}>
                <BulletItem icon={bulletIcon2}>
                  Descubrir lo que realmente quieres hacer con tu vida.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Fijarte metas realistas y lograr cumplirlas.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Reconocer tu valor y talentos | Mejorar tu Autoestima.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lograr mayor confianza y seguridad en ti misma.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Obtener Motivación y mantenerte motivada.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Manejar tus emociones y bloqueos emocionales.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Vencer tu miedos, dudas y gestionar la Iincertidumbre.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lograr tu perfecto balance entre emoción, razón e intuición.{" "}
                </BulletItem>
              </Grid>
              <Grid item md={6}>
                <BulletItem icon={bulletIcon2}>
                  Tomar decisiones importantes, sin culpa ni remordimiento{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Vencer el perfeccionismo que te impide avanzar personal y
                  profesionalmente.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Superar tus limites.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Identificar y cambiar las ideas que te impiden avanzar.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Organizar tu tiempo para ser más productiva y lograr tus
                  metas.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Dejar de posponer lo que necesitas hacer.{" "}
                </BulletItem>
                {/* <BulletItem icon={bulletIcon2}>
                  Dejar de posponer lo que necesitas hacer.{" "}
                </BulletItem> */}
                <BulletItem icon={bulletIcon2}>
                  Lograr independencia emocional y financiera.{" "}
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lograr relaciones más saludables.{" "}
                </BulletItem>
              </Grid>
            </Grid>
          </Card>
        </Article>
      </Section>

      {/* <Section>
          <Article className="center">
          <div  className="bold playfair center font-size-large blue1 lineheight-large">
          ¿Tienes un problema en particular y quieres saber si te puedo ayudar?
          </div>
          <div className="raleway center font-size-small lineheight-large" >
          <br/>
          Te entiendo. Hablando es más sencillo.
          <br/>
          Por eso, charlemos para que puedas explicarme 
          <br/>
          qué es lo que sientes y lo que necesitas.
          </div>
          <br/>
          <div className="pink">
          VS escribeme un email / envíame un Whatsapp >> Verificar humanidad primero!!!
          <br/>
          Re-direcciona a la agenda y se EXPLICA lo que esta sesión NO es!! 
          </div>
          <CtaButton label="Agendar una Sesión de Descubrimiento" />
          </Article>
      </Section> */}

      <Section color="beige1">
        <br />
        <Article
          layout="wide"
          titleClassName="playfair bold turkis-blue1 font-size-verylarge  lineheight-large"
          //title="Mejor, que te lo digan las que ya han trabajado conmigo"
          //title="Esto es lo que dicen mis clientes"
          title="Ellas ya saben lo que es trabajar conmigo"
        >
          <Carousel dots arrows slidesToShow={2} slidesToScroll={1}>
            <Testimonial name={TestName_10} variation="small">
              {" "}
              {Testimonio_10}
            </Testimonial>
            <Testimonial name={TestName_9} variation="small">
              {" "}
              {Testimonio_9}
            </Testimonial>
            <Testimonial name={TestName_8} variation="small">
              {" "}
              {Testimonio_8}
            </Testimonial>
          </Carousel>
        </Article>
      </Section>

      <Section color="blue1" id="Center-text" small>
        <Article className="white center font-size-medium lineheight-large">
          <span className=" playfair font-size-large">
            Descarga mi Guía{" "}
            <span className="bold">
              "Cómo descubrir tu propósito y conectar con tu confianza"
            </span>
          </span>
          <Newsletter tag="GIVEAWAY" value="RECONECTA" label="Quiero la guía" />
        </Article>
      </Section>

      <Footer />
    </>
  )
}
